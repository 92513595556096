import React, { useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import "../css/forgot.css";
import "../scss/login.scss";

function Forgot() {
  const [Itn_email, setItn_email] = useState("itn_email_users: Itn_email");
  const [Itn_otp, setItn_otp] = useState("");

  const function_expired = async () => {
    try {
      const result_check_mail = await axios.post(
        "https://track.itnprosolutions.com/check_mail_otp",
        { itn_email_users: Itn_email }
      );

      if (result_check_mail.data.status === "success") {
        const result = await axios.post("https://track.itnprosolutions.com/check_otp", {
          itn_email_users: Itn_email,
        });

        if (result.data.status === "failed") {
          const Toast = Swal.mixin({
            toast: true,
            showConfirmButton: false,
            position: "top-end",
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.onmouseenter = Swal.stopTimer;
              toast.onmouseleave = Swal.resumeTimer;
            }
          });
          Toast.fire({
            icon: "error",
            title: "OTP ของคุณยังไม่หมดอายุ",
          });
        } else {
          const response = await axios.post(
            "https://track.itnprosolutions.com/itn_get_otp",
            { itn_email_users: Itn_email }
          );

          if (response.data.status === "failed") {
            const Toast = Swal.mixin({
              toast: true,
              showConfirmButton: false,
              position: "top-end",
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.onmouseenter = Swal.stopTimer;
                toast.onmouseleave = Swal.resumeTimer;
              }
            });
            Toast.fire({
              icon: "error",
              title: "Server Error",
            });
          } else {
            handle_timer_otp();
            const Toast = Swal.mixin({
              toast: true,
              showConfirmButton: false,
              position: "top-end",
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.onmouseenter = Swal.stopTimer;
                toast.onmouseleave = Swal.resumeTimer;
              }
            });
            Toast.fire({
              icon: "success",
              title: "OTP ของคุณจะหมดอายุใน 3 นาที",
            });
          }
        }
      } else {
        const Toast = Swal.mixin({
          toast: true,
          showConfirmButton: false,
          position: "top-end",
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.onmouseenter = Swal.stopTimer;
            toast.onmouseleave = Swal.resumeTimer;
          }
        });
        Toast.fire({
          icon: "error",
          title: "ไม่พบบัญชี!",
        });
      }
    } catch (error) {
      const Toast = Swal.mixin({
        toast: true,
        showConfirmButton: false,
        position: "top-end",
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.onmouseenter = Swal.stopTimer;
          toast.onmouseleave = Swal.resumeTimer;
        }
      });
      Toast.fire({
        icon: "error",
        title: "An error occurred",
      });
    }
  };

  const function_count_time = function (time_set, display_time) {
    var timer = time_set,
      minutes,
      seconds;
    const interval = setInterval(() => {
      minutes = parseInt(timer / 60, 10);
      seconds = parseInt(timer % 60, 10);

      minutes = minutes < 10 ? "0" + minutes : minutes;
      seconds = seconds < 10 ? "0" + seconds : seconds;

      display_time.textContent = minutes + ":" + seconds;

      if (--timer < 0) {
        clearInterval(interval);
        const remove_e_a = document.getElementById("show_timer_a");
        const remove_e_span = document.getElementById("timer_otp");
        remove_e_a.remove();
        remove_e_span.remove();
      }
    }, 1000);
  };

  const handle_timer_otp = () => {
    const show_timer_a = document.createElement("a");
    const show_timer_span = document.createElement("span");
    show_timer_span.setAttribute("id", "timer_otp");
    show_timer_a.setAttribute("id", "show_timer_a");
    show_timer_a.textContent = "Your OTP Will Expire in ";
    show_timer_span.textContent = "00:00";

    document.querySelector("#p_timer").appendChild(show_timer_a);
    document.querySelector("#p_timer").appendChild(show_timer_span);

    var threeMinutes = 50 * 3.6,
      display_time = document.querySelector("#timer_otp");
    function_count_time(threeMinutes, display_time);
  };

  const Verify_otp = async function () {
    const verify_otp_endpoint = "https://track.itnprosolutions.com/verify_otp_itn";

    try {
      const verify_otp_itn = await axios.post(verify_otp_endpoint, {
        itn_mail_veri: Itn_email,
        itn_otp_veri: Itn_otp,
      });

      if (verify_otp_itn.data.status === "otp_complete") {
        localStorage.setItem("token_change_pass", verify_otp_itn.data.token);
        const Toast = Swal.mixin({
          toast: true,
          showConfirmButton: false,
          position: "top-end",
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.onmouseenter = Swal.stopTimer;
            toast.onmouseleave = Swal.resumeTimer;
          }
        });
        await Toast.fire({
          title: "การตรวจสอบ OTP สำเร็จ",
          icon: "success",
        }).then(() => {
          window.location.href = "/change_password";
        });
      } else {
        const Toast = Swal.mixin({
          toast: true,
          showConfirmButton: false,
          position: "top-end",
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.onmouseenter = Swal.stopTimer;
            toast.onmouseleave = Swal.resumeTimer;
          }
        });
        Toast.fire({
          title: "การตรวจสอบ OTP ล้มเหลว",
          icon: "error",
        }).then(() => {
          window.location.reload();
        });
      }
    } catch (error) {
      const Toast = Swal.mixin({
        toast: true,
        showConfirmButton: false,
        position: "top-end",
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.onmouseenter = Swal.stopTimer;
          toast.onmouseleave = Swal.resumeTimer;
        }
      });
      Toast.fire({
        title: "Error!",
        icon: "error",
      }).then(() => {
        window.location.reload();
      });
      console.error("Error verifying OTP:", error);
    }
  };

  return (
    <div className="main_forgot">
      <form className="Forgot-form-container">
        <h3>ITN Reset Password System</h3>
        <div className="input_forgot">
          <label>Your Email</label>
          <input
            type="email"
            className="form-control mb-2"
            placeholder="Enter email"
            onChange={(e) => setItn_email(e.target.value)}
          />
          <a
            className="btn btn-primary btn-sm form-control"
            onClick={function_expired}
          >
            Send OTP!
          </a>
        </div>
        <div id="p_timer" className="text-center mt-2"></div>
        <div className="mb-2">
          <label>OTP</label>
          <input
            id="otp_veri"
            className="form-control"
            placeholder="Enter OTP"
            onChange={(e) => setItn_otp(e.target.value)}
          />
        </div>
        <div className="d-grid">
          <a className="btn btn-primary btn-sm" onClick={Verify_otp}>
            Verify Your OTP!
          </a>
        </div>
        <p className="forgot-password text-right mt-2">
          <a href="/login">Login in?</a>
        </p>
      </form>
    </div>
  );
}

export default Forgot;
