import { useEffect } from "react";
import axios from 'axios';
import React, { useState } from 'react';
import Swal from 'sweetalert2'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

function NavComponents() {

    const [isAuthenticated, setIsAuthenticated] = useState(false);

    function itn_logout() {
        axios.post("https://track.itnprosolutions.com/itn_logout", {})
            .then(function (response) {
                if (response.data.status === "itn_success") {
                    localStorage.removeItem('token');
                    Swal.fire({
                        title: "Logout Success",
                        text: "You have been logged out successfully",
                        icon: "success"
                    }).then(function () {
                        window.location = "/login";
                    });
                } else {
                    console.log("Error");
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }


    useEffect(() => {
        const token = localStorage.getItem('token');


        if (token) { // ตรวจสอบว่ามี Token ใน Local Storage หรือไม่
            axios.post("https://track.itnprosolutions.com/auth", {}, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                }
            }).then((response) => {
                /* console.log(response.data);  */
                if (response.data.status === 'ok') {
                    setIsAuthenticated(true);
                } else {
                    Swal.fire({
                        title: "Auth Failed!!",
                        text: "Please Login!!",
                        icon: "error"
                    }).then(window.location = '/login')
                }
            }).catch((error) => {
                /* console.error('Token verification error:', error); */
                Swal.fire({
                    title: "Auth Failed!!",
                    text: "Please Login!!",
                    icon: "error"
                }).then(window.location = '/login')
            });
        } else {
            window.location = '/login'; // ถ้าไม่มี Token ให้ redirect ไปหน้า login
        }
    })

    return (
        <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark" fixed="top">
            <Container>
                <Navbar.Brand href="/main">ITN Tracking</Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="me-auto">
                        <Nav.Link href="/main">Home</Nav.Link>
                        <NavDropdown title="Your Service" id="collapsible-nav-dropdown">
                            <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                            <NavDropdown.Item href="#action/3.2">
                                Another action
                            </NavDropdown.Item>
                            <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item href="#action/3.4">
                                Separated link
                            </NavDropdown.Item>
                        </NavDropdown>
                    </Nav>
                    <Nav>
                        <Nav.Link href="#deets"><img src="user.png" style={ {width: "20px", marginLeft: "5px"} } /></Nav.Link>
                        <Nav.Link className="text-danger" onClick={itn_logout}>
                            <img src="logout.png" style={ {width: "20px", marginLeft: "5px"} } />
                        </Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default NavComponents;
