import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";

function Change_password() {
  const [u_password, Set_u_password] = useState("");
  const [confirm_password, Set_confirm_password] = useState("");

  const token_re = localStorage.getItem("token_change_pass");

  const change_password_func = async () => {
    if (confirm_password === u_password) {
      const change_password_post = await axios.post("https://track.itnprosolutions.com/change_password", {token_re_p: token_re, u_password_p: u_password});
      if (change_password_post.data.status === "change_password_success") {
        Swal.fire({
          title: "Password Has been change.",
          icon: "success",
        }).then( () => {
          localStorage.removeItem("token_change_pass");
          window.location.href = "/";
        });
      } else {
        Swal.fire({
          title: "an Error pls Contact Admin!.",
          icon: "error",
        });
      }
    } else {
      Swal.fire({
        title: "Your Password Is NOt Math!",
        icon: "error",
      });
    }
  }

  useEffect( () => {
    // HOLD
    const check_token_for_reset = async () => {
      const check_token_reset = localStorage.getItem("token_change_pass");
      const check_token_from_server_post = await axios.post("https://track.itnprosolutions.com/check_token_into_page_reset", {check_token_reset_p :check_token_reset});
      if (check_token_from_server_post.data.status === "success_token_verify_change") {
        Swal.fire({
          title: "let's Change Your Password!",
          icon: "success",
        });
      } else {
        window.location.href = "/";
      }
    }
    check_token_for_reset();
  }, []);

  return (
    <form className="Forgot-form-container">
      <h3>ITN Change Password System!</h3>
      <div id="p_timer" className="text-center mt-2"></div>
      <div className="mb-2">
      <label>New Password</label>
        <input
          id="new_password"
          className="form-control"
          placeholder="New Password"
          onChange={(e) => Set_u_password(e.target.value)}
        />
      </div>
      <div className="mb-2">
        <input
          id="new_password"
          className="form-control"
          placeholder="Confirm Password"
          onChange={(e) => Set_confirm_password(e.target.value)}
        />
      </div>
      <div className="d-grid">
        <a className="btn btn-primary btn-sm" onClick={change_password_func}>
          Change Password
        </a>
      </div>
    </form>
  );
}

export default Change_password;
