import "../scss/login.scss";
import React, { useState } from 'react';
import { Link } from 'react-router-dom'
import axios from 'axios';
import Swal from 'sweetalert2';

function Login() {
  const [u_username, setU_username] = useState("");
  const [u_password, setU_password] = useState("")
  const [Datalist, setDataList] = useState([]);

  const handle_login = () => {

    axios.post("https://track.itnprosolutions.com/itn_login",
      {
        u_username: u_username,
        u_password: u_password
      }).then(function (response) {
        if (response.data.status === "success") {
          const token = response.data.token;
          localStorage.setItem("token", token);

          const Toast = Swal.mixin({
            toast: true,
            showConfirmButton: false,
            position: "top-end",
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.onmouseenter = Swal.stopTimer;
              toast.onmouseleave = Swal.resumeTimer;
            }
          });
          Toast.fire({
            icon: "success",
            title: "ยินดีต้อนรับเข้าสู่ระบบ",
          }).then(
            () => {
              setTimeout(() => {
                window.location.href = "/main";
              }, 100);
            }
          )
        } else if (response.data.status === "password_incorrect") {
          const Toast = Swal.mixin({
            toast: true,
            showConfirmButton: false,
            position: "top-end",
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.onmouseenter = Swal.stopTimer;
              toast.onmouseleave = Swal.resumeTimer;
            }
          });
          Toast.fire({
            icon: "error",
            title: "รหัสผ่านไม่ถูกต้อง",
          });
        } else if (response.data.status === "user_lock") {
          const Toast = Swal.mixin({
            toast: true,
            showConfirmButton: false,
            position: "top-end",
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.onmouseenter = Swal.stopTimer;
              toast.onmouseleave = Swal.resumeTimer;
            }
          });
          Toast.fire({
            icon: "error",
            title: "บัญชีถูกล็อค",
          });
        } else if (response.data.status === "not_found_user") {
          const Toast = Swal.mixin({
            toast: true,
            showConfirmButton: false,
            position: "top-end",
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.onmouseenter = Swal.stopTimer;
              toast.onmouseleave = Swal.resumeTimer;
            }
          });
          Toast.fire({
            icon: "error",
            title: "ไม่พบผู้ใช้งาน",
          });
        }
      })
  }

  return (
    <div className="Auth-form-container">
      <div className="Auth-form">
        <div className="Auth-form-content">
          <h3 className="Auth-form-title">ITN Pro Solutions <br /> Track Your Products</h3>
          <div className="form-group mt-3">
            <input
              onChange={(e) => setU_username(e.target.value)}
              type="text"
              require
              className="form-control mt-1"
              placeholder="Username"
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  handle_login();
                }
              }}
            />
          </div>
          <div className="form-group">
            <input
              onChange={(e) => setU_password(e.target.value)}
              type="password"
              require
              className="form-control mt-1"
              placeholder="Password"
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  handle_login();
                }
              }}
            />
          </div>
          <div className="d-grid gap-2 mt-3">
            <button type="submit" className="btn btn-primary" onClick={handle_login}>
              Submit
            </button>
          </div>
          <div className="d-grid gap-2 mt-2">
              <Link as={Link} to={'/forgot'}>Forgot Password</Link>
          </div>
        </div>
      </div>
      <div className="stars">
        <div className="star text-light"></div>
        <div className="star text-light"></div>
        <div className="star text-light"></div>
        <div className="star text-light"></div>
        <div className="star text-light"></div>
        <div className="star text-light"></div>
        <div className="star text-light"></div>
        <div className="star text-light"></div>
        <div className="star text-light"></div>
        <div className="star text-light"></div>
        <div className="star text-light"></div>
        <div className="star text-light"></div>
        <div className="star text-light"></div>
        <div className="star text-light"></div>
        <div className="star text-light"></div>
        <div className="star text-light"></div>
        <div className="star text-light"></div>
        <div className="star text-light"></div>
        <div className="star text-light"></div>
        <div className="star text-light"></div>
        <div className="star text-light"></div>
        <div className="star text-light"></div>
        <div className="star text-light"></div>
        <div className="star text-light"></div>
        <div className="star text-light"></div>
        <div className="star text-light"></div>
        <div className="star text-light"></div>
        <div className="star text-light"></div>
        <div className="star text-light"></div>
        <div className="star text-light"></div>
        <div className="star text-light"></div>
        <div className="star text-light"></div>
        <div className="star text-light"></div>
        <div className="star text-light"></div>
        <div className="star text-light"></div>
        <div className="star text-light"></div>
        <div className="star text-light"></div>
        <div className="star text-light"></div>
        <div className="star text-light"></div>
        <div className="star text-light"></div>
        <div className="star text-light"></div>
        <div className="star text-light"></div>
        <div className="star text-light"></div>
        <div className="star text-light"></div>
        <div className="star text-light"></div>
        <div className="star text-light"></div>
        <div className="star text-light"></div>
        <div className="star text-light"></div>
        <div className="star text-light"></div>
        <div className="star text-light"></div>
      </div>
    </div>
  );
}

export default Login;
