import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Main from './src/main';
import Login from './src/login';
import Forgot from './src/forgot';
import Change_password from './src/change_password';


const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Navigate to="/login" />} />
        <Route path="/login" element={<Login />} />
        <Route path="/main" element={<Main />} />
        <Route path="/forgot" element={<Forgot />} />
        <Route path="/change_password" element={<Change_password />} />
      </Routes>
    </Router>
  );
};

export default App;
