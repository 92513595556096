import { useEffect } from "react";
import axios from "axios";
import React, { useState } from 'react';
import Swal from 'sweetalert2';
import Nav from '../components/NavComponents';
import '../css/widget.css';

function Main() {

  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem('token');

    if (token) { // ตรวจสอบว่ามี Token ใน Local Storage หรือไม่
      axios.post("https://track.itnprosolutions.com/auth", {}, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      }).then((response) => {
        /* console.log(response.data);  */
        if (response.data.status === 'ok') {
          setIsAuthenticated(true);
        } else {
          Swal.fire({
            title: "Auth Failed!!",
            text: "Please Login!!",
            icon: "error"
          }).then(window.location = '/login')
        }
      }).catch((error) => {
        /* console.error('Token verification error:', error); */
        Swal.fire({
          title: "Auth Failed!!",
          text: "Please Login!!",
          icon: "error"
        }).then(window.location = '/login')
      });
    } else {
      window.location = '/login';
    }
  })

  return (
    <div className="main_main">
      <Nav />
      <main className="main">
        <div className="container bootstrap snippets">
          <div className="row">
            <div className="col-md-3 col-sm-6 col-xs-12 w-30">
              <div className="panel panel-dark panel-colorful">
                <div className="panel-body text-center">
                  <p className="text-uppercase mar-btm text-sm">Website Service</p>
                  <img className="fa fa-users fa-5x w-50" src={process.env.PUBLIC_URL + '/img-panel/app-development.png'} />
                  <hr />
                  <p className="h2 text-thin">1</p>
                  <small><span className="text-semibold"><i className="fa fa-unlock-alt fa-fw"></i>1</span> Approved From Admin</small>
                </div>
              </div>
            </div>
            <div className="col-md-3 col-sm-6 col-xs-12 w-30">
              <div className="panel panel-danger panel-colorful">
                <div className="panel-body text-center">
                  <p className="text-uppercase mar-btm text-sm">Network Service</p>
                  <img className="fa fa-comment fa-5x w-50" src={process.env.PUBLIC_URL + '/img-panel/local-area-network.png'} />
                  <hr />
                  <p className="h2 text-thin">1</p>
                  <small><span className="text-semibold"><i className="fa fa-unlock-alt fa-fw"></i>1</span> Approved From Admin</small>
                </div>
              </div>
            </div>
            <div className="col-md-3 col-sm-6 col-xs-12 w-30">
              <div className="panel panel-primary panel-colorful">
                <div className="panel-body text-center">
                  <p className="text-uppercase mar-btm text-sm">Technical Service</p>
                  <img className="fa fa-shopping-cart fa-5x w-50" src={process.env.PUBLIC_URL + '/img-panel/technical-support.png'} />
                  <hr />
                  <p className="h2 text-thin">0</p>
                  <small><span className="text-semibold"><i className="fa fa-unlock-alt fa-fw"></i>1</span> Approved From Admin</small>
                </div>
              </div>
            </div>
            <div className="col-md-3 col-sm-6 col-xs-12 w-30">
              <div className="panel panel-info panel-colorful">
                <div className="panel-body text-center">
                  <p className="text-uppercase mar-btm text-sm">Total Your Service</p>
                  <img className="fa fa-dollar fa-5x w-50" src={process.env.PUBLIC_URL + '/img-panel/quality-assurance.png'} />
                  <hr />
                  <p className="h2 text-thin">1</p>
                  <small><span className="text-semibold"><i className="fa fa-unlock-alt fa-fw"></i>1</span> Approved From Admin</small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main >
    </div>
  );
}

export default Main;
